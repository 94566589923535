@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
html { font-family: 'Lato', sans-serif; -webkit-font-smoothing: antialiased; }
body { font-family: 'Lato', sans-serif; -webkit-font-smoothing: antialiased; background: #69A227; }

img { max-width: 100%;}
a { text-decoration: none !important; }
.fw-medium { font-weight: 500}
.fw-semi { font-weight: 600}

header { position: relative; }
.tophead {padding: 5px; border: 1px solid rgba(105, 162, 39, 0.50); border-radius: 50px;}
.tophead ul {margin: 0; padding: 0; list-style: none;}
.tophead-right { text-align: right; display: flex; align-items: center; justify-content: end;}
.tophead-right li { padding-left: 10px;}
.tophead-right li:first-child { padding-left: 0px;}
.tophead ul li { list-style: none; position: relative; }
.tophead ul li a {  text-decoration: none ;}
// .tophead ul li:last-child {padding-right: 0px;}
.user-dropdown {  margin-left: -10px; }
.tophead h1 { color: #7E7977; font-weight: 700; font-size: 28px; margin-bottom: 0px; }
.tophead p { color: #9F8F88; font-weight: 400; font-size: 13px; margin-bottom: 0px; display: flex; align-items: center; padding-top: 5px; }
.tophead p img { margin-right: 8px; height: 13px; }

.tophead-right .client-loginHead a { background: rgba(183, 65, 14, 0.1); border: 2px solid rgba(183, 65, 14, 0.5); padding-right: 45px !important;
border-radius: 5px 0px 0px 5px; font-weight: 400; font-size: 12px; padding: 5px 20px 5px 20px; margin-bottom: 0px; position: relative; }
.dots { position: absolute; height: 18px; width: 18px; background: #69A227; border-radius: 50%; right: -6px; top: -6px; text-align: center; font-size: 12px; font-weight: 600; color: #ffffff; }
.bell-img { height: 25px; }
.tophead .client-loginHead a {color: #737373;}
.tophead-right .client-loginHead span { font-weight: 700; }
.client-loginHead a img { position: absolute; top: -20px; left: 128px; height: 56px; width: 56px; object-fit: cover; border: 1px solid #D49377; border-radius: 50%; }

.main-box { background: #ffffff; border-radius: 20px; margin: 20px;}
.page-headpaddingbox { padding: 40px 10px 23px 10px; width: 100%;}
.pageinnner-padding { padding: 15px 40px 34px 40px; position: relative; }

.logo-box {padding: 50px 20px; text-align: center; }

.leftmenu-box { padding: 20px 0px 20px 20px; margin: 50px 0px;}

.leftmenu-box ul { margin: 0; padding: 0; }
.leftmenu-box ul li  { list-style: none; font-weight: 500; font-size: 18px; margin-bottom: 13px; padding: 16px 20px 16px 35px; position: relative;}
.leftmenu-box ul li a { color: #FFFFFF; text-decoration: none; }
.leftmenu-box ul li a img {padding-right: 10px; height: 25px;}

.leftmenu-box ul li.active { background: #69A227; border-radius: 50px 0px 0px 50px;}
.leftmenu-box ul li.active::after {position: absolute; content: " "; top: 59px; right: 0px; height: 80px; width: 40px; border-top-right-radius: 80px; -moz-border-top-right-radius: 80px; -webkit-border-top-right-radius: 80px; -webkit-box-shadow: 0 -40px 0 0 #69A227; -moz-box-shadow: 0 -40px 0 0 #69A227; box-shadow: 0 -40px 0 0 #69A227;}
.leftmenu-box ul li.active::before {position: absolute; content: " "; top: -80px; right: 0px; height: 80px; width: 40px; border-bottom-right-radius: 80px; -moz-border-bottom-right-radius: 80px; -webkit-border-bottom-right-radius: 80px; -webkit-box-shadow: 0 40px 0 0 #69A227; -moz-box-shadow: 0 40px 0 0 #69A227; box-shadow: 0 40px 0 0 #69A227;}


.leftmenu-box ul li .point-circle { content: ""; position: absolute; display: block; width: 10px; background-color: #ffffff; height: 10px; border-radius: 50px; top: 25px; left: -14px;} 
.leftmenu-box ul li.active .icon-dashboard:before {content: "\e906";  color: #ffffff;}

.leftmenu-box ul li.active a { color: #ffffff; }
.leftmenu-box ul li.active a i { color: #FFFFFF;}

.download-appbox {border-radius: 20px; margin-top: 30px; }
// .top-imgdowload { background: url(../images/top-download.svg) right top; background-repeat: no-repeat;  }
.bottom-imgdowload {padding: 27px 27px;}
.download-appbox h1 { font-weight: 800; font-size: 28px; color: #FFFFFF; margin-bottom: 0px; text-align: center;}
.soical-img {text-align: center; padding-top: 40px;}
.soical-img img {margin-right: 15px;}
// .copy-rightbox { background: #353535; border-radius: 20px 20px 0px 0px; padding: 18px 20px; margin-top: 30px; }
// .copy-rightbox p { font-weight: 400; font-size: 13px; color: #FFFFFF; margin-bottom: 0px; text-align: center; }
.head-text h1 { font-weight: 600; font-size: 23px; color: #69A227; margin-bottom: 0px; border-bottom:  1px solid rgba(0, 0, 0, 0.20); padding-bottom: 20px; } 

.seller-box { background: #FFFFFF; box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1); padding: 10px; height: 310px; position: relative;}

// .seller-boxbgimg { background: url(../images/table-topbg.png) right top; background-repeat: no-repeat; }
// .seller-bottomimg { background: url(../images/bottom-img2.svg) left bottom; background-repeat: no-repeat; }
// .seller-bottomimglast { background: url(../images/bottom-img.svg) right bottom; background-repeat: no-repeat; }

.seller-table tbody {position: absolute; height: 230px; overflow-y: scroll;}
.seller-table tbody::-webkit-scrollbar {overflow: hidden;}
.seller-table thead th { border-bottom: 0px;  border-top: none; padding: 15px 0px; line-height: 25px; }
.seller-table td { vertical-align: middle; padding: 10px !important; border-top: none !important; font-weight: 400; font-size: 12px; color: #7E7977; }
.seller-table td p { margin: 0px; font-size: 14px; font-weight: 600;}
.seller-table td .flag-img { width: 34px; height: 34px;}
.seller-table td .flag-img img { width: 100%; height: 100%; object-fit: cover; border-radius: 50px;}
.seller-table .bold-textcontent { font-weight: 700; font-size: 21px; color: #656565; text-align: right; margin-bottom: 0px; line-height: 20px; }

.seller-table thead th:first-child { font-weight: 600; font-size: 17px; color: #7E7977; vertical-align: top;}
.seller-table .last-text  { font-weight: 700; font-size: 33px; color: #656565; vertical-align: top; text-align: right; margin-bottom: 0px; }
.border-top h4 { font-weight: 600; font-size: 16px; color: #FF5501; text-align: center; padding: 10px 10px; }

.sec-table td p { color: #7E7977; font-size: 13px; font-weight: 300; margin-bottom: 0px; line-height: 15px; }
.sec-table td { vertical-align: top !important; }
.sec-table td .user-pics { width: 40px; border-radius: 50%; height: 40px; }
.sec-table td .user-pics img { width: 100%; height: 100%; border-radius: 50%; object-fit: cover; }
.sec-table td .green-badge {  background: rgba(40, 199, 111, 0.2); border-radius: 30px; padding: 4px 10px; color: #28C76F; font-weight: 600; font-size: 12px; }
.sec-table td .red-badge { background: rgba(255, 85, 1, 0.2); border-radius: 30px; font-weight: 600; font-size: 12px; padding: 4px 10px; color: #FF5501; }
.sec-table td:last-child { text-align: center; }

// model======================

.modal { position: fixed; top: 0; left: 0; z-index: 1050; width: 100%; height: 100%; outline: 0; padding: 0 !important; }

.home-pop  .btn-close{ background: url(../images/cancel.png) no-repeat top right;  border: none; height: 25px; width: 25px; filter: invert(1); }
.home-pop  { background:  #404040; }
.home-popcontent ul { margin: 0; padding: 0; padding-top: 20px; }
.home-popcontent ul li { list-style: none; color: #ffffff; margin-bottom: 20px; font-size: 15px; font-weight: 600; }
.home-popcontent ul li a {  color: #ffffff; }
.home-popcontent ul li a i { margin-right: 15px; }
.modal-dialog { position: relative; width: auto; pointer-events: none; }
.qr-popup .modal-dialog { background: none; }
.qr-popup  .btn-close  { display: none; }
.modal.fade.modal-dialog-slideout .modal-dialog {
-webkit-transform: translate(-100%) scale(1); 
transform: translate(-100%) scale(1);  
margin: 0 0 0 auto;
height: 100%;
}
.modal.fade.show.modal-dialog-slideout .modal-dialog {
     /* -webkit-transform: translate(0); */
transform: translate(0); 
display: flex;
align-items: stretch;
-webkit-box-align: stretch;
min-height: 100%;
}
.modal-content {
position: relative;
display: flex;
flex-direction: column;
width: 100%;
pointer-events: auto;
background-color: transparent;
background-clip: padding-box;
border: 0 solid transparent;
border-radius: 0;
outline: 0;
}
.modal-body { position: relative;  flex: 1 1 auto; padding: 1rem 40px;  overflow-y: auto;  overflow-x: hidden; }
.modal-open .modal { overflow-x: hidden;  overflow-y: auto; }
.modal-footer { text-align: left; align-items: flex-start; justify-content: flex-start; padding: 1rem 30px; border-top: 0 solid #eeeef1;
border-bottom-right-radius: 0;  border-bottom-left-radius: 0; }
.modal-header {
     // display: block;
padding: 35px 40px 0px 40px; border-bottom: 0 solid #eeeef1; border-top-left-radius: 0; border-top-right-radius: 0; }

// ============================
.user-headtext  {  position: absolute; top: 19px; }
.user-headtext h1 { border-bottom: none; padding-bottom: 0px; }
.newtext { top: 0 !important;}
.user-tabs { padding-bottom: 15px; }
.cost-tab .nav-link  {   padding: 8px 15px !important; font-size: 14px !important; }
.cost-tab .nav-link.active  { font-size: 14px !important; }
.user-tabs .nav-link.active {  background: #69A227; border-radius: 0px; font-weight: 700; font-size: 16px; color: #FFFFFF; border: none; }
.user-tabs  .nav-link { font-weight: 700; font-size: 16px; color: #69A227; padding: 8px 30px;}
.user-tabs  .nav-link:hover { border: none; }
.user-tabs  .nav-link { border: none;}  
.user-tabs .nav-item { background: rgba(105, 162, 39, 0.15); padding: 6px;}
// .user-tabs .nav-item:last-child { border-top-right-radius: 50px; border-bottom-right-radius: 50px; }
// .user-tabs .nav-item:first-child {  border-top-left-radius: 50px; border-bottom-left-radius: 50px; }

.serch-input { background: #ffffff; border: 1px solid #CACACA; border-radius: 5px;  font-weight: 500; font-size: 13px;
padding: 17px; padding-left: 45px !important; position: relative; text-overflow: ellipsis; }
.serch-input:focus {border: 1px solid #CACACA; border-radius: 5px;  box-shadow: none; outline: none; }
.serch-input::placeholder { color: #ACACAC; }
.filter-box { background: #FFFFFF; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05); border-radius: 3px; text-align: center;  
width: 36px; height: 36px; line-height: 36px; float: right;}
.search-img { position: absolute; top: 10px; left: 28px; }

.buy-table { background: #FFFFFF; }
.buy-table td { font-weight: 400; font-size: 12px; color: #374151; border-top: none; vertical-align: middle; padding-left: 15px; }
.buy-table thead th { border-bottom: 1px solid #F7F7F7; font-weight: 400; font-size: 12px; color: #374151; padding-left: 30px; vertical-align: middle; border-top: none; }
.standard-btn { background: rgba(105, 162, 39, 0.20); border-radius: 0px; color: #69A227; font-weight: 700; font-size: 10px; padding: 7px 10px; }
.details-btn { border: 1px solid #69A227; color: #69A227; font-weight: 700; font-size: 11px; padding: 3px 12px; border-radius: 0px;}
.details-btn:hover { background: #69A227; color: #FFFFFF; border: 1px solid #69A227; }
.buy-table tr td:first-child { color: #1F2937; font-weight: 700; }
.buy-table tr th:first-child { color: #1F2937; font-weight: 700;  }
.premium-btn { background: rgba(104, 104, 104, 0.25); border-radius: 0px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: rgba(81, 81, 81, 1); }


// Review Status
.reply-btn { background:#D4F4E2; border-radius: 0px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #28C76F; }
.publish-btn { background: rgba(251, 188, 5, 0.15); border-radius: 0px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #FBB605; }
.replied-btn{ background: #FF550126; border-radius: 0px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #FF5501; }
.unPublished-btn { background: #0052B433; border-radius: 0px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #0052B4; }
.viewed-btn { background: #4D47C333; border-radius: 0px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #4D47C3; }


// ContactUs
// .contact-view-btn { background:#D4F4E2; border-radius: 9px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #28C76F;}
// .contact-viewed-btn { background:#FF550126; border-radius: 9px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #FF5501; }
.contact-responded-btn { background: rgba(151, 71, 255, 0.15); border-radius: 0px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #9747FF; }



// HelpDesk
.helpDesk-view-btn { background:#D4F4E2; border-radius: 9px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #28C76F; }
.helpDesk-viewed-btn { background:#FF550126; border-radius: 9px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #FF5501; }
.helpDesk-responded-btn { background:#FBBC0526; border-radius: 9px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #FB9905; }


//Subscriptions Status
// .standard-btn {background:#D4F4E2; border-radius: 9px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #28C76F; }
.permium-btn {background:url(../images/premium-btn-bg.png); border-radius: 0px; font-weight: 700; font-size: 10px; padding: 7px 10px; color: #000000; }

.buy-table tr td:nth-child(5) { color: #374151; font-weight: 500; }
.buy-table tr td:nth-child(4) { color: #4B5563; }

.buy-table tr:nth-child(2) th:first-child { border-top-left-radius: 5px !important; }
.buy-table tr:nth-child(2) th:last-child { border-top-right-radius: 5px !important; }
.head-td { color: #69A227 !important; font-weight: 500; }

// 
.filter-popup .modal-dialog { background: #FFFFFF; }
.filter-popup.fade.modal-dialog-slideout .modal-dialog {
       -webkit-transform: translate(100%) scale(1) !important; 
        transform: translate(100%) scale(1) !important; 
       margin: 0 0 0 auto;
       height: 100%;
}  
.filter-popup.fade.show.modal-dialog-slideout .modal-dialog {
    /* -webkit-transform: translate(0); */
    transform: translate(0) !important; 
    display: flex;
    align-items: stretch;
    -webkit-box-align: stretch;
    min-height: 100%;
}
.filter-popup .btn-close{ background: url(../images/cancel.png) no-repeat top right; border: none; height: 25px; width: 25px; }
.pop-head h1 { color: #FF5501; font-weight: 600; font-size: 22px; margin-bottom: 0px; }

// Login
.login-bg{ background: url(../images/login-bg.png); background-repeat: no-repeat;  background-position: center; background-size: cover;
width: 100%; height: 120vh; }
.login-form { background: rgba(255, 255, 255, 0.5); border-radius: 20px;   padding: 45px 20px; }
.login-form h1 { font-weight: 500; color: #000000;  font-size: 18px; margin-bottom: 0px; padding-top: 40px; }

.login-form input { background: #F0EFFF; border-radius: 8px; padding: 28px 21px; border: none;  color: #69A227; font-size: 16px; font-weight: 400;  margin-top: 35px; }
.login-form input::placeholder { color: #69A227; }
.login-form input:focus { border: none; outline: none; box-shadow: none; background: #F0EFFF; border-radius: 8px; }
.verfication input:last-child { margin-right: 0px; }
.verfication input { width: 41px; margin-right: 15px; padding: 17px 21px; }

.login-btn { background: #69A227; box-shadow: 0px 4px 61px rgba(77, 71, 195, 0.4); border-radius: 9px; color: #FFFFFF; font-size: 16px; font-weight: 500; padding: 13px 20px; }
.login-btn:hover { color: #ffffff; text-decoration: none; }
.login-form a { text-decoration: none; }
.login-form p { font-weight: 600; font-size: 15px; color: #69A227; margin-top: 0px; text-align: right; margin-bottom: 0px; }
.login-form p a { color: #69A227; }

// details
.user-deatilsbox { background: #FFFFFF; border-radius: 0px; box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.10);}
.user-deatilsbg { background: url(../images/details-userbg.png); background-repeat: no-repeat;  background-position: center; background-size: cover;
border-radius: 0px;  min-height: 140px;}
.userdetails-pic { margin: 0 auto; width: 170px; margin-top: -80px; }
.userdetails-pic img { width: 100%; height: 100%; object-fit: cover; border-radius: 20px; }
.user-deatilsbox h1 { color: #7E7977; font-weight: 700; font-size: 28px; text-align: center; margin-bottom: 0px; padding-bottom: 12px;}
.padding-userdetails { padding: 15px; }
.social-text { color: #7E7977; font-weight: 400; font-size: 16px; align-items: center;  }
.deatils-box { padding-left: 20px; padding-top: 15px; }
.social-icon { width: 24px; margin-right: 10px; }

.details-rightobx {background: #F9F9F9; border-radius: 0px; padding: 5px 15px; }
.details-rightobx h1 {  color: #7E7977; font-weight: 500; font-size: 18px; margin-bottom: 0px; padding-top: 10px; display: flex; align-items: center; }
.details-rightobx h1 img { margin-right: 10px; }
.details-rightobx p { color: #B0B0B0; font-weight: 400; font-size: 13px; margin-bottom: 0px; }
.details-rightobx h6 { color: #2B2A2A; font-weight: 400; margin-bottom: 0px; font-size: 12px; }
.my-subscriptionbox { background: #F5E7FF; border-radius: 0px; padding: 25px; margin-top: 0px; }

.newpremium-btn { background: linear-gradient(95.68deg, #D386FF 25.21%, #A25CFE 75.5%); border-radius: 0px; font-weight: 700; font-size: 12px;
color: #FFFFFF; padding: 2px 8px; }
.my-subscriptionbox h1 { font-weight: 600; font-size: 18px; color: #69A227;  margin-bottom: 0px;}
.my-subscriptionbox p { font-weight: 600; font-size: 12px; color: #2B2A2A; margin-bottom: 0px; }
.my-subscriptionbox h6 { font-weight: 600; font-size: 12px; color: #606060; margin-bottom: 0px; }
.subscription-btn { background: #69A227; border-radius: 0px; padding: 3px 25px; font-weight: 600; font-size: 12px; color: #FFFFFF; }
.subscription-btn:hover { color: #FFFFFF; }
.deatilslogo-box {height: 170px; width: 100%;}
// .deatilslogo-box { height: 100%; width: 100%;}
.deatilslogo-box img {  width: 100%; height: 100%; object-fit: contain;}

.add-text { top: 0px !important; }
.add-form { background: #ffffff; border-radius: 5px; border: 1px solid #ced4da; padding: 20px 20px;  font-weight: 400; font-size: 14px; }
.add-form:focus { background: #ffffff; border-radius: 8px; border: 1px solid #ced4da; outline: none; box-shadow: none;  padding: 20px 20px;}
.add-select { background: #ffffff; border-radius: 5px; border: 1px solid #ced4da; height: 42px; }
.add-select:focus { background: #ffffff; border-radius: 8px; border: 1px solid #ced4da; outline: none; box-shadow: none; }
.buy-table img { height: 18px; margin-right: 3px; }

// 
.padding-rightnone { padding: 15px 50px 34px 0px; }
.content-menubox { padding-right: 10px;  margin-top: 15px;}
.content-menubox ul { margin: 0; padding: 0; }
.content-menubox ul li  { list-style: none; font-weight: 600; font-size: 16px; margin-bottom: 13px; padding: 8px 10px 8px 28px; }
.content-menubox ul li a { color: #353535; text-decoration: none; }
.content-menubox ul li a i { padding-right: 10px; font-size: 18px; }
.content-menubox ul li.active { background: #69A227; border-radius: 0px 20px 20px 0px;  }
.content-menubox ul li.active a { color: #FFFFFF !important; }
.content-menubox ul li.active::before {  content: ""; position: absolute; display: block; width: 4px; background-color: #FFFFFF;
height: 23px; left: 15px; border-radius: 20px; } 
.right-contentbox { padding-left: 15px;}
.head-content p { color: #69A227; font-weight: 600; font-size: 18px; margin-bottom: 0px;}
.contentimg-box { width: 100%; height: 215px; }
.contentimg-box img { width: 100%; height: 100%; object-fit: cover; }

.upload-btn-wrapper {position: relative; overflow: none; display: block;}
.uploadBtn { background: #69A227; color: #FFFFFF; outline: none; text-align: center; font-size: 13px;
padding: 4px 18px; border: 1px solid #69A227; font-weight: 600; }
.upload-btn-wrapper input[type=file] {font-size: 100px; position: absolute; left: -124px; top: -40px; opacity: 0; cursor: pointer; height: 500px;
font-size: 20px; width: 900px;}
.lenth-box { margin-bottom: 0px; display: flex; align-items: center; font-weight: 600; font-size: 14px; color: #7E7977;
background: rgba(105, 162, 39, 0.10); border: 1px solid #69A227; padding: 10px 15px; }
.lenth-box  img { margin-right: 13px; }
.content-input { text-overflow: ellipsis;  overflow: hidden; white-space: nowrap; border: 1px solid #D8D6DE; border-radius: 5px; font-weight: 500; font-size: 17px; color: #B4B4B4; padding: 23px 20px;
background: #F7F7F7; color: #000000 !important; }
.content-input:focus {  color: #000000 !important; border: 1px solid #D8D6DE; border-radius: 5px; background: #F7F7F7; outline: none; box-shadow: none; }
.content-input::placeholder { color: #9F9F9F !important; }

.right-contentbox h6 { font-weight: 600; font-size: 18px; color: #373737; margin-bottom: 0px; display: flex; align-items: center; padding-left: 35px; margin-top: 15px; }
.right-contentbox h6 span { width: 8px !important; height: 8px !important; border-radius: 50%; background: #373737; display: block; margin-right: 10px; }
.bottom-imgcont { width: 139px; height: 93px; margin-bottom: 20px; }
.bottom-imgcont img { width: 100%; height: 100%; object-fit: cover; }
.cont-manage { margin-right: 15px; display: inline-block; }
.cont-imgmain .cont-manage:last-child { margin-right: 0px;}

//============ 
.sparess-textbox { background: rgba(84, 84, 84, 0.05); padding: 18px 30px; }
.sparess-textbox h1 { color: #9F9F9F; margin-bottom: 0px; font-weight: 500; font-size: 24px; line-height: 37px; }
// .sparess-textbox .orange-textsparess { font-weight: 700; color: #FF5501; }
.sparess-textbox .balck-textsparess { color: #000000; font-weight: 500; }
.sparess-textbox h2 { color: #7D7D7D; font-weight: 600; font-size: 19px; margin-bottom: 0px; line-height: 32px; }
.sparess-textbox h2 span { font-weight: 700; font-size: 20px; }
.sparess-textbox h3 { font-weight: 700; font-size: 20px; line-height: 24px; text-align: center; color: #FF5501; margin-bottom: 0px;}

//================
.login-form h6 { font-weight: 400; font-size: 14px; margin-bottom: 0px; }
.login-form h6 a { color: #000000; }
.new-loginbtn { background: rgba(255, 85, 1, 0.7); border-radius: 9px; font-weight: 400; font-size: 13px; color: #FFFFFF; padding: 7px 26px; }
.new-loginbtn:hover { color: #FFFFFF; }
.new-dots { background: #FF5501 !important; }

.avail-Offer { background: linear-gradient(90.14deg, rgba(53, 53, 53, 0.62) 0.09%, #FF5501 99.88%); padding: 30px 10px; }
.avail-Offer h1 { font-weight: 300; font-size: 22px; color: #FFFFFF; margin-bottom: 0px; }
.avail-Offer h1 span { font-weight: 700; }
.avail-Offer p  {  font-weight: 300; font-size: 16px; color: #FFFFFF; margin-bottom: 0px; padding-top: 8px; }
.white-btn { background: #FFFFFF; border-radius: 5px; font-weight: 700; font-size: 20px; color: #FF5501; padding: 7px 30px;}
.white-btn:hover { color: #FF5501; }
.posttable { background: #FFFFFF; border-radius: 10px; }
.posttable th { border-top: none; padding: 7px 7px 7px 20px;}
.posttable thead { background: #FFFFFF; box-shadow: 0px 1px 25px 2px rgba(0, 0, 0, 0.1); border-radius: 10px 10px 0px 0px; }
.posttable thead th { border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom: none; font-weight: 500; font-size: 18px; 
color: #7E7977;}
.eyes-imgsparess { height: 12px; margin-left: 5px; }
.posttable td { font-weight: 400; font-size: 15px; color: #7E7977; vertical-align: middle; border-top: 1px solid #F0F0F0; padding: 7px 7px 7px 20px; }

.chat-table td, .chat-table th { border-top: none; }
.chat-table thead { background: #FFFFFF; box-shadow: 0px 1px 25px 2px rgba(0, 0, 0, 0.1); border-radius: 10px 10px 0px 0px; }
.chat-table { background: #FFFFFF; border-radius: 10px; }
.chat-table thead th { border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom: none; font-weight: 500; font-size: 18px; color: #7E7977; vertical-align: middle; padding: 5px 10px 5px 10px;  }
.chat-table thead th:last-child { text-align: right; }
.chat-table td h2 { margin-bottom: 8px; font-weight: 600; font-size: 16px; color: #B9B9B9; }
.chat-table td .typing-text { font-weight: 500; font-size: 12px; color: #11A242; padding-top: 10px; }
.chat-table td:last-child { text-align: right; }
.chat-table td .fire-text { font-weight: 500; font-size: 11px; color: #AAACAD; }

.chat-table td { font-weight: 300; font-size: 12px; color: #AAACAD; vertical-align: top; padding: 5px 7px 5px 7px; position: relative; border-top: 1px solid #F0F0F0; }
.chat-table thead th p { margin-bottom: 0px; font-weight: 500; font-size: 28px; color: #000000; }
.chat-table td  .badge-red  { background: #B70E0E; width: 18px; height: 18px; border-radius: 50%; font-weight: 800; font-size: 12px; color: #FFFFFF; margin-top: 5px;  }
.sparess-user { width: 45px; height: 45px; border-radius: 50%; position: relative; }
.sparess-user img { width: 100%; height: 100%; object-fit: cover;  border-radius: 50%;}
.green-dots { width: 10px; height: 10px; line-height: 10px; border-radius: 50%; background: #11A242; display: block; position: absolute;
left: 44px; bottom: 11px; }
.chat-table td h6 { font-weight: 300; font-size: 15px; color: #FF5501; margin-bottom: 0px; }
.chat-table td h4 { font-weight: 800; font-size: 12px; color: #000000; margin-bottom: 0px; }

.new-chattable td { vertical-align: middle;  padding: 11px 9px 10px 15px; }
.new-chattable td:last-child { text-align: center; }
.my-Adsbox { background: #EBF2E8; border: 1px solid #FFFFFF; border-radius: 10px; padding: 20px 23px; min-height: 332px;}
.my-Adsbox h4 { font-weight: 600; font-size: 24px; color: #FF5501; margin-bottom: 0px; }
.my-Adsbox p { background: #F7F7F7; width: 40px; height: 40px; border: 1px solid #11A242; border-radius: 50%; text-align: center; 
line-height: 40px; margin-bottom: 0px; font-weight: 600; font-size: 26px; color: #11A242; }
.my-Adsbox h2 { font-weight: 400; font-size: 14px; color: #2B2A2A; margin-bottom: 0px; }
.my-Adsbox h3 { font-weight: 600; font-size: 16px; color: #606060; margin-bottom: 0px; }

.sparessborder-btn { border: 1px solid #69A227; border-radius: 0px; font-weight: 600; font-size: 14px; color: #69A227 !important; padding: 6px 25px; }
.sparessfill-btn { background: #69A227; border-radius: 0px; font-weight: 600; font-size: 14px; color: #FFFFFF; padding: 6px 25px;} 
// .sparessborder-btn:hover { color: #FF5501; }
.sparessfill-btn:hover { color: #ffffff; }
.leftmenu-box ul li.active .icon-new-dashbord:before { color: #FF5501; }
.buy-msgimg { width: 30px; height: 30px; }
.buy-msgimg img { width: 100%; height: 100%; object-fit: cover; }

.profile-editbox {  background: url(../images/profile-editbg.png); background-repeat: no-repeat;  background-position: center;
background-size: cover; border-radius: 180px 0px 0px 0px; min-height: 200px; position: relative; }
// .profile-editbox img {    border-radius: 180px 0px 0px 0px;
//     height: 200px;
//     width: 100%;}

.label-star {  color: red; font-weight: 900; font-size: 17px; }

.bg-newimg { border-radius: 180px 0px 0px 0px; height: 200px !important; width: 100%; }

.edit-upload { position: absolute; right: 10px; bottom: 10px; }
.edit-form { padding-left: 40px; }
.edit-userpic { width: 130px; height: 130px; border-radius: 50%; margin-top: -50px;  border: 2px solid #FFFFFF; box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);}
 .edit-label { font-weight: 400;  font-size: 14px;  color: #2B2A2A; }
// .edit-userpic img { width: 100%; height: 100%; object-fit: cover; border-radius: 50%; }
.edit-userpic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    position: relative;
    z-index: 9;
  }

.edit-form h1 { font-weight: 500; font-size: 22px; color: #7E7977; margin-bottom: 0px; padding-top: 15px; }
.edit-form p { font-weight: 400; font-size: 16px;  color: #7E7977; margin-bottom: 0px; }
.edit-input { border: 1px solid rgba(126, 121, 119, 0.5); border-radius: 5px; background: transparent; }
.edit-input:focus { border: 1px solid rgba(126, 121, 119, 0.5); border-radius: 5px; background: transparent; outline: none; box-shadow: none; }
  
.new-save { font-size: 16px;  padding: 8px 45px; }
.new-cancel { font-size: 16px;  padding: 8px 45px; background: #FFFFFF; }
// .edit-userallow {  background: rgba(255, 85, 1, 0.2); border-radius: 50%;  border: none; 
//     padding: 6px; width: 25px; height: 25px; line-height: 6px; }
.edit-userallow {
    background: rgba(105,162,39,.2);
    border-radius: 50%;
    border: none;
    padding: 6px;
    width: 25px;
    height: 25px;
    line-height: 6px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 999;
    }

    .edit-userpic .upload-btn-wrapper { overflow: none; }

    // .upload-btn-wrapper .edit-upload { overflow: visible; }

    .profile-editbox  .upload-btn-wrapper input[type="file"] { z-index: 9999; top: -30px; height: max-content; width: fit-content; }

    
    .upload-btn-wrapper input[type="file"] { z-index: 9999; height: 50px; width: fit-content; }
     .new-allow input[type="file"] { z-index: 9999; height: 50px; width: fit-content; top: 80px !important; }


    


.new-upload { position: relative;
    position: relative;
    left: 0px;
    top: 0px;
    width: 130px;
    height: 130px;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
   
    border-radius: 50%;}


.paid-box { background: #EAC8B9;  border-radius: 20px; }
// .edit-userallow img { width: 100%; height: 100%; object-fit: cover; }

.paid-bg {  background: url(../images/paid-bottom.png) bottom left; background-repeat: no-repeat;  }
.paid-box h1 { font-weight: 400; font-size: 32px; color: #F5F5F5; margin-bottom: 0px; padding-top: 25px; }
.paid-box p { color: #FF5501; font-weight: 600; font-size: 35px; margin-bottom: 0px; padding-bottom: 16px; }
.paid-content h1 { font-weight: 600;  font-size: 18px;  color: #FF5501; margin-bottom: 0px; }
.paid-content p { font-weight: 500; font-size: 13px;  color: #969696; margin-bottom: 0px; padding-top: 10px; line-height: 22px; }
.left-paidbox { background: #FFAA80; box-shadow: 0px 8px 20px 15px rgba(0, 0, 0, 0.05); }
.left-paidbox h1 { display: flex; align-items: center; border-bottom: 1px solid #F2F2F2; font-weight: 400; font-size: 18px; padding: 10px 0px; justify-content: center; color: #FFFFFF; margin-bottom: 0px;  }
.left-paidbox h1 img { margin-right: 5px; }
.left-paidboxpadding { padding: 15px; }
.paid-textbox { display: flex; align-items: center; justify-content: space-between; margin-bottom: 8px; }
.paid-textbox p { font-weight: 400; font-size: 12px; color: #FFFFFF;  margin-bottom: 0px; margin-right: 25px; }
.paid-textbox h6 { margin-bottom: 0px; font-weight: 400; font-size: 16px; color: #FFFFFF; }

.right-paidbox { background: #FFFFFF; }
.right-paidbox p { color: #FF5501; }
.right-paidbox h6 { color: #FF5501; }
.right-paidbox h1 { color: #FF5501;  }
.new-whitebtn { font-size: 14px; padding: 6px 25px; }
.feature-includes { background: #FFFFFF; border-radius: 10px; padding: 15px; margin-top: -95px; }

//posting===========

 .posting-tab .nav-tabs { border-bottom: 1px solid #E2E2E2;}
.posting-tab .nav-link.active { font-weight: 600; font-size: 22px; color: #FF5501; background: none; border-bottom: 2px solid #FF5501; }
.posting-tab .nav-link.active:hover { border-bottom: 2px solid #FF5501 !important; }
.posting-tab .nav-link:hover { border: none; }
.posting-tab .nav-link { border: none; font-weight: 400; font-size: 17px; color: #FF5501; padding: 15px 20px 10px 0px; margin-right: 50px; }

.attachments-text { background: rgba(255, 255, 255, 0.5); border: 1px solid #E2E2E2; border-radius: 5px; margin-bottom: 0px;
padding: 5px; font-weight: 400;  font-size: 12px; color: #8D8D8D; display: flex; align-items: center; line-height: 25px; }
.attachments-text img { margin-right: 7px;}
.posting-box { background: #FFFFFF;  border-radius: 10px;  padding: 5px 15px;}
.post-input { border: 1px solid #C2C2C2; border-radius: 5px; font-weight: 400; font-size: 14px; color: #9F8F88; padding: 0.375rem 0.95rem; }
.post-input:focus { border: 1px solid #C2C2C2; border-radius: 5px; outline: none; box-shadow: none; }
.post-input::placeholder { color: #9F8F88; }
.post-label { font-weight: 600; font-size: 14px; color: #7E7977; }
.small-text { font-weight: 400; font-size: 12px; color: #7E7977; }
.browser-post { background: #F8F8F8; border: 1px dashed #7B7B7B; border-radius: 4px;  padding: 5px 9px; display: flex;
justify-content: center;  flex-direction: column;}
.browser-post p { font-weight: 400; font-size: 10px; color: #7B7B7B; margin-bottom: 0px; padding-top: 5px; }
.post-clickbtn { background: none; border: none; color: #7582FA; font-weight: 400px !important; font-size: 10px !important; padding: 0px !important; }l
.new-padding { padding: 5px 8px; }
.post-imgbox { width: 100%; height: 150px; }
.post-imgbox img { width: 100%; height: 100%; object-fit: cover; border-radius: 4px; }

.posting-box h6 { font-weight: 500; font-size: 15px; color: #969696; margin-bottom: 0px; }
.check-label { font-weight: 400 !important; font-size: 12px !important; color: #6E6B7B !important; }
.online-text { font-weight: 400; font-size: 12px; color: #737373; }
.remaining-box { background: #FFFFFF; border-radius: 10px; padding: 25px 15px; }
.remaining-box h1 { font-weight: 700; font-size: 20px;  color: #9F8F88; margin-bottom: 0px; }
.remaining-box h6 { margin-bottom: 0px; font-weight: 500; font-size: 14px;  color: #9F8F88; margin-bottom: 0px; padding-top: 5px; }
.remaining-box h5 { font-weight: 700; font-size: 16px; color: #9F8F88; margin-bottom: 0px; margin-top: 1.5rem; }
.remaining-box h3 { font-weight: 400; font-size: 24px; color: #9F8F88; margin-bottom: 0px; }
.posting-table thead th  { font-weight: 500; font-size: 18px; color: #FF5501; border-bottom: 1px solid #E2E2E2; padding: 0.75rem 0.75rem 0.75rem 0rem ;  }
.posting-table td, .table th { border-top: none; } 
.posting-table td { font-weight: 500; font-size: 16px;  color: #000000; vertical-align: middle; padding: 0.75rem 0.75rem 0.75rem 0rem ; }
.posting-table td:last-child { text-align: right; }
.posting-table th:last-child { text-align: right; }
.update-btn { background: rgba(255, 85, 1, 0.3);  border-radius: 5px; font-weight: 500; font-size: 15px; color: #FF5501; padding: 5px 15px; }
.update-btn:hover { color: #FF5501;  }
.table-imgbox { display: flex; align-items: center; }
// .table-imgbox img { margin-right: 8px; }
.table-imgbox p { font-weight: 600; font-size: 16px;  color: #3D3D3D; margin-bottom: 0px; line-height: 20px; }
.table-firstimg { width: 60px; height: 60px; margin-right: 7px; }
.table-firstimg img { width: 100%; height: 100%; object-fit: cover; border-radius: 5px; }
.forth-tablepost { background: #FFFFFF; border-radius: 10px; padding: 15px 20px; }
.forth-tablepost h1 {  font-weight: 500; font-size: 15px; color: #969696;  margin-bottom: 0px;}
.forth-tablepost h1 span { font-weight: 700; font-size: 15px;   color: #969696; padding-right: 20px; }
.forth-tablepost h6 { font-weight: 600; font-size: 23px; color: #7E7977; padding-top: 20px; margin-bottom: 0px; }

// ======
.user-sellernewbox  ul { margin: 0; background: #FFFFFF; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05); border-radius: 50px; padding: 8px 4px; }
.user-sellernewbox ul li { display: inline; list-style: none; padding: 8px 22px; font-weight: 700; font-size: 17px;   }
.user-sellernewbox ul li a { color: #FF5501; text-decoration: none; }
.user-sellernewbox ul li.active  { background: #FF5501; border-radius: 50px; }
.user-sellernewbox ul li.active a { color: #ffffff; }

// .new-seller {background: rgba(105, 162, 39, 0.15); box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05); padding: 8px;}
.new-seller ul { margin: 0; padding: 0; }
.new-seller ul li { display: inline-block; list-style: none; background: rgba(105, 162, 39, 0.15);   padding: 8px; 
    //  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}
.new-seller { float: right; }
// .new-seller ul li:first-child { border-top-left-radius: 50px; border-bottom-left-radius: 50px; }
// .new-seller ul li:last-child { border-top-right-radius: 50px; border-bottom-right-radius: 50px; }
.new-seller  ul li button {  font-weight: 700; font-size: 15px;  color: #69A227 !important; padding: 5px 20px; }
// .new-seller  ul li button:hover {  color: #69A227;}
.new-seller  ul li button.active {  background: #69A227; border-radius: 0px; color: #FFFFFF !important; }
.new-seller  ul li button:focus {  box-shadow: none; outline: none; } 



// new-podtting tab
.new-posttingtab ul { margin: 0; padding: 0; border-bottom: 1px solid #E2E2E2; padding-bottom: 12px; }
.new-posttingtab ul li { list-style: none; display: inline; font-weight: 400;  font-size: 17px; margin-right: 50px; padding: 0px 14px 14px 0px; }
.new-posttingtab ul li a {  color: #FF5501;  text-decoration: none;  }
.new-posttingtab ul li.active { font-weight: 600; font-size: 22px; border-bottom: 2px solid #FF5501; }


//=======================
.switch { position: relative;  display: inline-block;  width: 30px; height: 18px; margin-bottom: 0px;}
.switch input {  opacity: 0;  width: 0; height: 0; }
.slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ffffff ; 
-webkit-transition: .4s;  transition: .4s; border: 1px solid #000000;}
.slider:before { position: absolute; content: ""; height: 12px; width: 12px; left: 3px; bottom: 2px; background-color: #686868; 
-webkit-transition: .4s;  transition: .4s;}
input:checked + .slider { background-color: #69A227; border: 1px solid #69A227;}
input:focus + .slider { box-shadow: 0 0 1px #6B6B6B;}
input:checked + .slider:before { -webkit-transform: translateX(26px); -ms-transform: translateX(26px);  transform: translateX(26px);  left: -11px; background-color: #ffffff;}
/* Rounded sliders */
.slider.round { border-radius: 34px; }
.slider.round:before { border-radius: 50%; }

.advertisement-box { background: linear-gradient(105.49deg, rgba(183, 65, 14, 0.35) 11.18%, #EAC8B9 66.99%); border-radius: 10px; }
.feature-includes h1 { font-weight: 600; font-size: 18px; color: #FF5501; margin-bottom: 0px; text-align: center; }
.feature-includes ul { margin: 0; padding: 0; }
.feature-includes ul  li {  list-style: none; font-weight: 500; font-size: 11px; color: #838383; margin-top: 15px; background: url(../images/star-add.svg); background-repeat: no-repeat; padding-left: 30px; }
.servicespadding-left { padding-left: 30px; }
.settingpadding-left { padding-left: 40px; }
.settingpadding-left h1 { font-weight: 600;  font-size: 27px;  color: #7E7977; margin-bottom: 0px; display: flex; align-items: center; padding-bottom: 15px;
border-bottom: 1px solid rgba(0, 0, 0, 0.13); }
.settingpadding-left h1 img { margin-right: 25px; }

.enquiry-box h6 { font-weight: 600;  font-size: 18px;  color: #B9B9B9; margin-bottom: 0px; }
.enquiry-box h6 span { font-weight: normal; }
.enabled-text { font-weight: 600;  font-size: 12px;   color: #FF5501; }
.enquiry-box { padding: 25px 0px; border-bottom: 1px solid rgba(0, 0, 0, 0.13); }
.desk-from { background: linear-gradient(116.55deg, #EAD4C9 7.8%, rgba(231, 129, 86, 0.1) 89.39%);  border-radius: 10px; margin-top: 20px; }

.desk-formbg { background: url(../images/new-contactbg.png) right bottom; background-repeat: no-repeat; padding: 45px 15px 45px 45px; border-radius: 10px; }
.allover-padding {  padding: 20px 20px 20px 10px !important; }
.desk-from h6 { font-weight: 700;  font-size: 28px;  color: #000000;  margin-bottom: 0px;}
.desk-from p { font-weight: 400; font-size: 18px;  color: #565656; margin-bottom: 0px; padding-top: 15px; line-height: 22px; }
.contact-box { font-weight: 600;  font-size: 16px;  color: #7E7977; align-items: center; display: flex; margin-top: 35px;}
.contact-box img { margin-right: 15px; }
.form-contactsetting { background: rgba(255, 255, 255, 0.4);  border: 1px solid #FFFFFF;   backdrop-filter: blur(5px); border-radius: 10px; padding: 25px 25px; }
.desk-from h4 { display: flex; align-items: center; margin-top: 55px; }
.desk-from h4 img { margin-right: 13px; }

.seller-table td:first-child { width: 50px;}
.blog-tab.nav-tabs {
    border-bottom: none !important;
}
.right-contentbox h3 {
    font-weight: 500;
    font-size: 18px;
    color: #373737;
    margin-bottom:  0px
;
}
.blog-select {
    border: 
1px
 solid #D8D6DE;
    border-radius: 
5px
;
    font-weight: 500;
    font-size: 17px;
    color: #B4B4B4;
    background: transparent;
    height: 
47.33px
;
}


.new-model .modal-content { background: #ffffff; }
.new-pophead {  font-weight: 600; font-size: 20px; color: #69A227; text-align: center; margin-bottom: 0px; }


.new-model .btn-close {
    background: url(../images/cross-pop.svg) no-repeat top right;
    border: none;
    height: 25px;
    width: 25px;
    position: absolute;
    right: -10px;
    top: -10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    background-position: center;
    background-color: #ffffff;
}
.pop-table { margin-top: 15px; }
.new-model .modal-body { padding: 0px; }
.new-model .modal-header { display: block; padding: 25px 40px 0px 40px; }
.pop-table td { padding: 0px 20px 0px 20px; border-bottom: 1px solid #dee2e6; border-top: none; line-height: 40px; font-size: 12.25px; }
.pop-table td:first-child {  font-weight: 700;  color: #3A3A3A;}
.pop-table td:nth-child(2) {  font-weight: 500; text-align: right; color: #999797;  }
.pop-table td:nth-child(3) { font-weight: 700; color: #3A3A3A; }
.pop-table td:nth-child(4) { font-weight: 500;  text-align: right; color: #999797; }
.last-row td {border-bottom: none;}


// drop
.new-drop.dropdown {
    position: relative;
    background: url(../images/menu-new.svg) no-repeat;
}
.new-drop .btn-primary {
    // color: #fff;
     background-color: transparent; 
     border-color: transparent; 
     box-shadow: none;
     outline: none;
     border: none;
     font-size: 10px;
}


// 
.new-drop .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: transparent; 
    border-color: transparent;
    box-shadow: none !important;
    outline: none !important;
    border: none !important; 
}

.new-drop .dropdown-menu { background: #FFFFFF !important; box-shadow: 2px 4px 14px rgba(0, 0, 0, 0.15) !important;
    border-radius: 5px !important; padding: 0px; margin: 0px; border: none; }
.new-drop .dropdown-item { font-weight: 400; font-size: 14px; color: #69A227; padding: 9px 1.5rem; border-bottom: 0.5px solid #F2F2F2 !important; }

.new-drop .dropdown-toggle::after {
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none; 
}

.add-label { font-weight: 600; font-size: 16px; color: #7E7977; }
.active-label {  font-weight: 400 !important; font-size: 15px !important;  color: #A0A0A0 !important; }
.note-text { font-weight: 400; font-size: 16px; color: #FF5501; margin-bottom: 0px; }


// 
.user-drop .dropdown-toggle::after { border-top: none; border-right: none; border-bottom: none; border-left: none; }
.user-drop button:focus { box-shadow: none; }
.user-drop button { padding: 0px;  }
.user-drop button img { border: 2px solid #D49377; border-radius: 50%; }
.user-drop .dropdown-item img { margin-right: 9px; }
.user-drop .dropdown-item { border-bottom: 0.5px solid #C6C6C6; font-weight: 400; font-size: 14px; color: #AAAAAA; padding: 0.8rem 0.7rem; }
.user-drop .dropdown-item:last-child { border-bottom: none; }
.user-drop .dropdown-menu { padding: 0px 0px; border: none; margin-top: 17px; }

.user-drop .dropdown-menu:after {
    bottom: 100%;
    left: 89%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 12px;
    margin-left: -30px;
}


.hide {
    display: none;
    background: #F8E7DE;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #FF5501;
    position: absolute;
    right: 75px;
    top: 59px;
    text-align: left;
    padding: 5px 10px;
  }

  .hide:after {
    bottom: 100%;
    left: 96%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #F8E7DE;
    border-width: 8px;
    margin-left: -30px;
}  
  .myDIV:hover + .hide { display: block; }
  .edit-input::placeholder{ color: #8A8A8A; }
  .pop-content h1 { margin-bottom: 0px; font-weight: 700; font-size: 32px; color: #FF5501; padding-top: 150px; }
  .pop-content h2 { font-weight: 400; font-size: 24px; color: #959595; margin-bottom: 0px; line-height: 28px; padding-top: 13px; }

// Feedback CSS
.somya-img{height: 80px; width: 80px; margin-right: 20px;}
  .somya-img img{height: 100%; width: 100%;}
  .profile-feed h3{ color: #000000; font-size: 20px; font-weight: 500;}
  .profile-feed p{ margin: 0px; color: #ABABAB; font-size: 18px; font-weight: 400;}

  .feed-descrip h5{  margin: 30px 0px;}
  .feed-descrip h5 img{height: 100%; width: 100%;}
  .feed-descrip h6{font-weight: 600; font-size: 20px; color: #5E5E5E; margin: 0px; margin-bottom: 10px;}
  .feed-descrip p{font-weight: 400; font-size: 18px; color: #9A9A9A; margin: 0px;}
  .descrip-img-selector{height: 100%; width: 100%; object-fit: contain;}
  .descrip-img-selector img{height: 100px; width: 100px; margin-right: 22px;}
//   .feed-reviews-area{border-bottom: 1px solid #FF5501;}
 .descrip-img-selector{ margin-top: 30px; margin-bottom: 25px;}
//  .button-field {border-top: 1px solid #69A227; padding-top: 15px;}
//  .button-field button{ margin: 0px 15px;}
 .button-field button img{margin-right: 10px;}
 .feed-reviews-area{padding-bottom: 180px;}

 .feed-text { 
    font-weight: 600;
    font-size: 14px;
   margin-bottom: 0px;
    
    color: #000000; }

.feed-descrip h5{height:22px; width: 100px; margin: 30px 0px;} .feed-descrip h5 img{height: 100%; width: 100%;}
.feed-descrip h6{font-weight: 600; font-size: 20px; color: #5E5E5E; margin: 0px; margin-bottom: 10px;} .feed-descrip p{font-weight: 400; font-size: 18px; color: #9A9A9A; margin: 0px;}
.descrip-img-selector{height: 100%; width: 100%; object-fit: contain;}
.descrip-img-selector img{height: 100px; width: 100px; margin-right: 22px;}
//   .feed-reviews-area{border-bottom: 1px solid #FF5501;}
.descrip-img-selector{ margin-top: 30px; margin-bottom: 25px;}
.button-field{border-top: 1px solid #69A227; text-align: center; padding-top: 35px;}
// .button-field button{ margin: 0px 15px;}
// .button-field button img{margin-right: 10px;}
.feed-reviews-area{padding-bottom: 180px;}
.somya-img{height: 80px; width: 80px; margin-right: 20px;}
.somya-img img{height: 100%; width: 100%;}
.profile-feed h3{ color: #000000; font-size: 20px; font-weight: 500;}
.profile-feed p{ margin: 0px; color: #ABABAB; font-size: 18px; font-weight: 400;}

.responded-btn{background: rgba(251, 188, 5, 0.15); border-radius: 9px; font-weight: 700; font-size: 10px; padding: 7px 10px; color: #FB9905;}
.query h2{ color: #69A227; font-size: 18px; font-weight: 600;}
.query p{margin: 0px; color: #747474; font-size: 16px; font-weight: 500;}
.query{margin-bottom: 25px;}
.reply-buttons{border-top: 1px solid #69A227; padding-top: 25px;}

.ad-head-center{font-size: 25px; font-weight: 600; margin: 0px; color: #69A227;}
.ad-sub-head h4{font-size: 20px; color: #69A227; font-weight: 400; margin: 0px;}
.ad-sub-head img{height: 27px;}
.video-plans{ background: rgba(105, 162, 39, 0.10); border: 1px solid rgba(105, 162, 39, 0.60); border-radius: 10px; padding: 20px; margin-top: 30px;}
.video-plans h1{font-size: 16px; font-weight: 700; color: #505050; margin: 0px;}
.ad-month-plans h2{ font-size: 16px; font-weight: 400; color: #505050; margin: 0px;}
.ad-month-plans h2 span{color: #69A227; font-size: 19px; font-weight: 700; margin-right: 10px;}

.globality{padding: 20px; }
.country-ranks{align-items: center; display: flex; padding-bottom: 10px;}   
.country-ranks img{height: 40px; width: 70px; margin-right: 20px;}
.country-ranks h2{font-size: 20px; font-weight: 400; margin: 0px; color: #7E7977;}
.globality .ranks h1{font-size: 25px; font-weight: 700; color: #7E7977; margin: 0px;}
.globality .ranks{padding-bottom: 10px;}
.country-globe-ranks {height: 400px; overflow-y: scroll; overflow-x: hidden;} 
.country-globe-ranks::-webkit-scrollbar {width: 5px;}
.country-globe-ranks::-webkit-scrollbar-track {background-color: rgba(255, 85, 1, 0.2); border-radius: 30px;}
// .cost-head{ display: flex; align-items: center; }
.form-group .text-area-lable{font-size: 12px; font-weight: 600; color: #5C4F4F; margin: 0px;}
.subs-allows p{margin: 0px; font-size: 15px; font-weight: 600; color: #373737; margin: 0px;}
.subs-allows{padding: 15px 25px; background: rgba(84, 84, 84, 0.05); height: 100px;}
.subs-allows h1{margin: 0px; font-size: 14px; font-weight: 500; color: #9A9A9A; display: flex;}
.subs-allows img{margin-right: 10px; height: 20px;}

.new-editbtn { padding: 3px 5px !important; }

.banner-uploadbtn input[type="file"] {
    width: 160px !important;
  }

  .banner-rightuplaodnew input[type="file"] {
    width: 135px !important;
  }

.head-content span{color: #616161; font-size: 12px; font-weight: 600; margin: 0px; padding: 10px 0px;}
.head-content span img{height: 14px; margin-right: 10px;}
.globality .form-group .serch-input{padding: 24px;}
.globality .form-group .search-img{top: 15px; left: 20px;}
.btn-analy { border: 1px solid #69A227; color: #69A227 !important; font-size: 15px; font-weight: 400; border-radius: 0px; padding: 10px 0px;}
.btn-analy:hover { border: 1px solid #69A227; background: rgba(105, 162, 39, 0.15);}
.btn-analy img{height: 18px; margin-right: 15px;}
.bulk-upload-btn input[type=file] {
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
    cursor: pointer;
    font-size: 20px;
    width: 200px !important;
    cursor: pointer;
}

 .bulk-upload-btn:hover {
    background-color: rgba(105,162,39,.15) !important; }

 .bulk-upload-btn button img { cursor: pointer; }
.buy-table tbody td .colored{color: #69A227; font-size: 13px; font-weight: 700; }
.buy-table thead th .colored{color: #69A227; font-size: 13px; font-weight: 700;}
.buy-table tbody td{vertical-align: middle;}
.total-revenue{ padding: 20px;}
.revenue-chart-box{background: #ffffff; padding: 15px 15px; }
.total-revenue .head-text h1{border-bottom: 0px; margin: 0px; font-size: 18px; font-weight: 500; }
.y-drops{display: flex; }
.y-drops .form-group select{border: 1px solid #69A227; color: #69A227; font-size: 12px; font-weight: 400; margin: 0px;}

.cloud-img { height: 30px; }
.new-remove { padding: 12.5px 26px;}

/* Transaction Status Color */
.failed-btn { background:#FF550126; border-radius: 0px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #FF5501; }
.succeeded-btn { background:#D4F4E2; border-radius: 0px; font-weight: 700; font-size: 10px; padding: 7px 10px; color: #28C76F; }
.pending-btn { background:#0052B426; border-radius: 0px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #0052B4; }



//=======

.side-menu-scroll {
    height: 670px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0px;
    padding-left: 20px;
}
.side-menu-scroll::-webkit-scrollbar { display: none; }
.pageinnner-padding::-webkit-scrollbar { display: none; } 
.right-contentbox{height: 640px; overflow-x: hidden; overflow-y: scroll;}
.right-contentbox::-webkit-scrollbar{display: none;}

.settingpadding-left{height: 425px; overflow-x: hidden; overflow-y: scroll;}
.settingpadding-left::-webkit-scrollbar{display: none;}

.pageinnner-padding {height:  500px; overflow-x: hidden; overflow-y: scroll;}

.new-usermainbox { width: 50px; height: 50px; border-radius: 50%; }
.new-usermainbox img { object-fit: cover; width: 100%; height: 100%; border: none !important;}

.userpic-btn { line-height: 0px; }
.new-eyestext { cursor: pointer; }
.filter{
    cursor: pointer;
}
.password-eyes { position: absolute; top: 18px;  right: 20px;}
.subscription{width: 1000px; height: 50px; white-space: nowrap; overflow-x: auto; overflow-y: hidden;}


.feedback-new { padding: 8px 20px !important; }

// ========================================================
.search-head input { background: transparent; border: 0px; padding-left: 60px;}
.search-head input:focus { outline: none; box-shadow: none; border: 0px; background-color: transparent;}
// input:focus {outline: none; box-shadow: none;}
.search-head { position: relative;}
.search-head img { position: absolute; top: 4px; left: 20px;}
.login-button { border: 1px solid #69A227; border-radius: 50px;}
.login-button .btn-admin:focus { outline: 0px; box-shadow: none; background-color: transparent;}
.login-button .btn-admin { font-size: 14px; font-weight: 400;  color: #69A227; padding: 5px 10px;}

.btn-admin .slider.round { border-radius: 34px;}
.btn-admin .slider.round:before { border-radius: 50%;}
.slider-round-text {padding-left: 10px; color: #000000; font-weight: 400;}
// .tophead-right-content { }
.user-profile p { margin: 0px; color: #657059; font-size: 17px; font-weight: 700;}
.side-menu-bg {background: #353535;}
.side-border{height: 6px; width: 100%; background: #69A227;}
.new-cost-head h1 {border-bottom: 0px; padding-bottom: 0px;}
.divider-line { border-bottom: 1px solid rgba(0, 0, 0, 0.2); margin: 20px 0px;}
.post-check [type="checkbox"].filled-in:checked + label:after { background: #69A227;}
.nav-radio [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:after { background-color: #69A227;}
.nav-radio [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:before, [type="radio"].with-gap:checked + label:after { border: 2px solid #69A227;}

.globality-count img { height: 20px;}
.globality-count span { font-size: 14px; font-weight: 600; padding-left: 5px;}
.globality-count { display: flex; align-items: center; float: right;}
.green-count span { color: rgba(40, 199, 111, 1);}
.red-count span { color: rgba(234, 84, 85, 1);}
// ========================================================
.chart-tabs .nav-item .nav-link {background-color: transparent; border-radius: 10px; border: 1px dashed #C0C0C0; margin-left: 15px;}
.chart-tabs .nav-item .nav-link .ch-nav-icon img { height: 40px;}
.chart-tabs .nav-item .nav-link .ch-nav-icon p { margin: 0px; color: #000000;}
.chart-tabs .nav-item .nav-link.active { background-color: rgba(105, 162, 39, 0.08); border: 1px solid rgba(105, 162, 39, 1);}
// .dash-chart .chart { min-height: 200px !important;}
.dash-chart .apexcharts-legend { display: none;}
.chart-head { position: absolute; top: 0px; left: 0px;}
.chart-head h1 { font-size: 18px; font-weight: 600; color: #7E7977; margin: 0px;}
.chart-head p { font-size: 12px; font-weight: 400; color: #69A227; margin: 0px;}
.manage-table thead { border: 1px solid #DBDADE !important;}
.manage-table thead td { border: none;}
.manage-table tbody { border: 1px solid #DBDADE !important;}
.manage-table thead td { color: var(--light-typography-color-heading-text, #4B465C) !important; font-size: 12px; font-weight: 600; line-height: normal; letter-spacing: 1px; text-transform: uppercase;}
.manage-table tbody td { vertical-align: middle; font-size: 14px; font-weight: 400; color: var(--light-typography-color-body-text, #4B465C);}
.btn-edit { padding: 8px 30px; font-size: 10px; background: rgba(105, 162, 39, 0.30); border-radius: 0px; font-size: 14px; font-weight: 500; color: #69A227 !important;}

// =========================================
.green-textsparess { color: #69A227; font-size: 24px; font-weight: 700;}
.sparess-textbox h1 span { font-family: 'Goldman', cursive;}
@import url('https://fonts.googleapis.com/css2?family=Goldman&family=Merriweather:wght@300;400;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
.darker-input-field input::placeholder { color: #000000 !important;}
.lenth-box-text { color: #69A227; font-size: 18px; font-weight: 600; margin: 0px;}
.banner-box { height: 140px;}
.grren-dot { height: 10px; width: 10px; border-radius: 50px; background: #69A227;}
.contact-field-area { display: flex; align-items: center;}
.contact-field-area img { height: 22px; width: 22px;}
.contact-field-area p { margin: 0px; color: #373737; font-size: 18px; font-weight: 700;}

.new-updatebtn button { background: rgba(105, 162, 39, 0.2);  border: none !important; justify-content: center; border-radius: 50px; padding: 5px 8px;}
.new-updatebtn { position: absolute; bottom: 5px; right: 5px;}

.new-updatebtn input[type="file"] {
    height: 50px !important;
    width: 50px !important;
}

.new-updatebtn input[type=file] {
    top: -15px !important;
    opacity: 0;
    right: 2px !important;
    left: auto !important;
}
.blog-select-drop { border: 1px solid rgba(216, 214, 222, 1); height: 55px;}
.new-update-btn { font-size: 15px; font-family: 600;}
.head-content .dark-p { color: #373737 !important;}
// .plus-text { font-size: 25px; font-weight: 800; margin: 0px;}
.btn-analy-2 { font-size: 13px; font-weight:  600;}
.premium-member-area {background: rgba(105, 162, 39, 0.30); padding: 5px;}
.premium-member-area p { margin: 0px; color: #69A227; font-size: 13px; font-weight: 600;}
.details-user ul { padding: 0px 15px;}
.details-user ul li { line-height: 35px; color: #4B465C; font-size: 15px; font-weight: 600;}
.details-user ul li span { padding-left: 10px;}
.details-user ul li .dets-span { color: #7E7977; font-weight: 400;}
.btn-listing { padding: 3px 6px; border-radius: 5px;}
.location-icon img { height: 20px;}
.location-icon p { margin: 0px; color: #7E7977; font-size: 13px; font-weight: 500;}
.video-filed-content iframe { height: 190px; width: 100%; border-radius: 5px; border: 0px;}
// ======================================================
.push-notification-pop .modal-content { background-color: #ffffff; border-radius: 10px; box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.15); height: 480px; width: 440px;}
.push-notification-pop { top: 85px; left: 430px;}
.push-notification-pop .modal-header {padding: 25px;}
.push-headtext { display: flex; justify-content: space-between; align-items: center; width: 100%;}
.push-headtext h1 { color: #69A227; font-size: 20px; font-weight: 600; margin: 0px;}
.push-headtext h5 { color: #4B465C; font-size: 15px; font-weight: 500; margin: 0px; display: flex;}
.push-notification-pop .modal-body {padding: 0px 0px; padding-top: 0px;}
.push-notification-pop .tab-content {height: 365px; overflow-y: scroll; overflow-x: hidden;}
.push-context h3 { color: #4B465C; font-size: 12px; font-weight: 500; margin-bottom: 8px;}
.push-context p { color: #69A227; font-size: 12px; font-weight: 600; margin: 0px;}
.push-context ul li { color: #69A227; font-size: 12px; font-weight: 600; border-bottom: none !important; padding: 0px !important;}   
.push-tabs-pop .nav-tabs { padding-left: 15px; border-bottom: 1px solid #E9E9E9;}
.push-tabs-pop .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active { border: none; border-bottom: 2px solid #69A227 !important;}
.push-tabs-pop .nav-tabs .nav-item .nav-link {padding-bottom: 5px; color: #5D586C; font-size: 15px; font-weight: 600; align-items: center;}
.push-tabs-pop .nav-tabs .nav-link:hover{ border-color: transparent;}
.push-tabs-pop .nav-tabs .nav-item .nav-link .badge { background-color: #69A227; color: #ffffff; border-radius: 2px; font-size: 12px; font-weight: 600; margin-left: 5px; padding: 3px 6px;}
.push-tabs-pop .nav-tabs .nav-item { margin-right: 20px;}
// .push-btm-border {}
.pushed-btn {font-size: 12px; font-weight: 600; padding: 5px 18px; margin-right: 15px; margin-top: 15px; border-radius: 5px; border: 0px;}
.pushed-btn-grn { background-color: #69A227; color: #FFFFFF;}
.pushed-btn-lgt { background: rgba(105, 162, 39, 0.20); color: #69A227;}
.push-notification-pop .tab-content::-webkit-scrollbar-thumb {border-radius: 40px; background: rgba(105, 162, 39, 0.50);}
.push-notification-pop .tab-content::-webkit-scrollbar { border-radius: 50px; background-color: transparent; width: 5px;}
.push-area .push-area-list li { padding: 18px 15px; border-bottom: 1px solid #E9E9E9;}
.push-area .push-area-list .unread-list { box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -10px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -25px inset; background-color: #69a22727;}
