/* ---------------------------------- Responsive Media Styles ---------------------------------*/

@media (min-width: 1200px) { 
.container { max-width: 1347px; }
}

@media only screen and (min-width: 769px) {
}

/* Medium : 647px */

@media only screen and (max-width: 768px) {
}

// @media only screen and (min-width: 576px) and (max-width:1024px) {

// .slider:before { height: 15px; 	width: 15px; left: 3px; bottom: 2px; }
// input:checked + .slider:before { left: -13px; }
// .switch { height: 20px; width: 30.5px; }
// .user-tabs .nav-link { font-size: 14px; padding: 6px 20px;}
// .sparess-textbox h3 { font-weight: 700; font-size: 13px; line-height: 20px; }
// .tophead ul li { margin: 0 10px; }
// .client-loginHead a img { left: 114px; height: 50px; top: -13px; }
// .page-headpaddingbox { padding: 25px 20px 20px 20px; }
// .pageinnner-padding { padding: 0px 20px 20px 20px; }
// .tophead h1 { font-size: 23px; }
// .tophead p { font-size: 11px; }
// .tophead-right .client-loginHead a { padding: 5px 20px 5px 12px; }
// .head-text h1 { font-size: 20px; padding-bottom: 10px; }
// .seller-table .last-text { font-size: 20px; }
// .seller-table thead th:first-child { font-size: 13px; }
// .seller-table td { font-size: 13px; padding: 10px 0px 0px 15px !important; }
// .seller-table td .flag-img { width: 40px;}
// .seller-table .bold-textcontent { font-size: 19px; }
// .border-top h4 { font-size: 14px; margin-bottom: 0px; }
// .logo-box { padding: 20px 15px 15px 15px; }
// .leftmenu-box { padding: 25px 15px 15px 0px; margin-top: 25px; }
// .leftmenu-box ul li { font-size: 13px; margin-bottom: 10px; padding: 10px 0px 10px 15px; }
// .leftmenu-box ul li a i { padding-right: 5px; font-size: 15px; }
// .seller-table thead th { line-height: 15px; }
// .download-appbox { margin-top: 25px; }
// .bottom-imgdowload { padding: 15px 15px; }
// .download-appbox h1 { font-size: 19px; }
// .soical-img { padding-top: 15px; }
// .copy-rightbox { padding: 15px 15px; margin-top: 25px;}
// .copy-rightbox p { font-size: 12px; }
// .login-bg { height: auto; }
// .verfication  input  { width: 37px; padding: 15px 5px !important; margin-right: 10px;   }
// .login-form p { font-size: 13px; }
// .login-form input { padding: 25px 21px; font-size: 14px; margin-top: 25px; }
// .login-btn { padding: 10px 20px; }
// .user-deatilsbg { min-height: 110px; }
// .userdetails-pic { width: 109px; }
// .padding-userdetails { padding: 5px; }
// .user-deatilsbox h1 { font-size: 18px; padding-bottom: 10px; }
// .social-icon { width: 15px; margin-right: 5px; }
// .social-text { font-size: 13px; }
// .details-rightobx p { font-size: 10px; }
// .deatils-box { padding-left: 2px; padding-top: 10px;}
// .user-deatilsbox { padding-bottom: 10px; }
// .details-rightobx h1 { font-size: 15px; }
// .my-subscriptionbox { padding: 15px; margin-top: 15px;}
// .deatilslogo-box { height: 115px; padding: 15px; }
// .cost-tab .nav-link { padding: 5px 5px !important; font-size: 11px !important; }
// .cost-tab .nav-link.active { font-size: 11px !important; }
// .cost-head { top: 5px !important; }
// .padding-rightnone { padding: 0px 20px 20px 0px; }
// .content-menubox ul li { font-size: 13px; padding: 8px 10px 8px 15px; }
// .contentimg-box { height: 90px; }
// .uploadBtn { font-size: 12px; padding: 3px 15px; }
// .lenth-box { font-size: 11px; padding: 5px 5px; }
// .lenth-box img { margin-right: 5px; height: 15px;}
// .content-input { font-size: 14px; padding: 19px 15px; }
// .right-contentbox h6 { font-size: 16px; padding-left: 25px; margin-top: 10px; }
// .head-content p { font-size: 16px; }
// .bottom-imgcont { width: 89px; height: 74px; margin-bottom: 13px; margin-top: 16px; }
// .cont-manage { margin-right: 9px;}
// .cont-imgmain .cont-manage:nth-child(4n) { margin-right: 0px;}
// .sparess-textbox { padding: 10px 10px; }
// .sparess-textbox h1 { font-size: 18px; line-height: 27px; }
// .sparess-textbox h2 { font-size: 16px; line-height: 27px; }
// .sparess-textbox h2 span { font-size: 18px;}

// //==========
// .avail-Offer { padding: 20px 10px; }
// .white-btn { font-size: 12px; padding: 5px 20px; }
// .chat-table thead th { font-size: 15px; padding: 5px 5px 5px 5px; }
// .sparess-user { width: 40px; height: 40px; }
// .green-dots { left: 39px; bottom: 29px;}
// .chat-table td h2 {	margin-bottom: 5px;	font-size: 14px;}
// .chat-table td h6 { font-size: 14px; }
// .chat-table thead th p { font-size: 24px; }
// .my-Adsbox { padding: 20px 10px; min-height: 444px; }
// .my-Adsbox h4 { font-size: 20px; }
// .my-Adsbox p { 	width: 30px; height: 30px; 	line-height: 28px; 	font-size: 20px; }
// .sparessborder-btn { font-size: 12px; padding: 5px 20px; }
// .sparessfill-btn { font-size: 12px; padding: 5px 20px; } 
// .posttable thead th { font-size: 16px; } 
// .posttable td { font-size: 13px; padding: 7px 7px 7px 15px; }
// .profile-editbox { min-height: 120px; }
// .posting-tab .nav-link { font-size: 15px; 	padding: 15px 15px 10px 0px; margin-right: 15px; }
// .post-label { font-size: 12px; 	}
// .post-input { font-size: 13px; 	padding: 5px 5px; 	text-overflow: ellipsis; white-space: nowrap; }

// .posting-box h6 { font-size: 12px; }
// .new-padding { padding: 0px 4px; }
// .post-clickbtn { font-size: 8px !important; }
// .browser-post p { font-size: 8px; padding-top: 3px; }
// .browser-post { padding: 5px 5px; }
// .attachments-text img { margin-right: 4px; }
// .remaining-box { padding: 15px 10px; }
// .remaining-box h6 { font-size: 12px; }
// .remaining-box h5 { font-size: 13px; margin-top: 0.8rem; }
// .remaining-box h1 { font-size: 18px; }
// .remaining-box { min-height: 99px; }
// .remaining-box h3 { font-size: 22px; }
// .posting-table thead th { font-size: 15px; line-height: 17px; }
// .update-btn { font-size: 12px;  padding: 3px 10px; }
// .forth-tablepost h6 { font-size: 17px; 	padding-top: 11px; 	}
// .forth-tablepost h1 { font-size: 13px; }
// .forth-tablepost h1 span { font-size: 13px; padding-right: 15px; }
// .paid-box h1 { padding-top: 20px; font-size: 21px; }
// .paid-box p { font-size: 28px; padding-bottom: 12px; }
// .paid-content h1 { font-size: 16px; }
// .paid-content p {  font-size: 12px; line-height: 18px; }
// .left-paidbox h1 { font-size: 15px; padding: 8px 0px;}
// .paid-textbox p { font-size: 10px; 	margin-right: 10px; }
// .paid-textbox h6 { font-size: 12px; }
// .paid-textbox { margin-bottom: 5px; }
// .left-paidboxpadding { 	padding: 7px; }
// .feature-includes { padding: 8px; margin-top: -55px; }
// .feature-includes h1 { font-size: 14px; }
// .feature-includes ul li { font-size: 10px; margin-top: 5px; padding-left: 25px;	}

// .edit-label { font-size: 13px; }
// .edit-userallow { padding: 0px; }
// .settingpadding-left { padding-left: 21px; } 
// .settingpadding-left h1 { font-size: 23px; }
// .settingpadding-left h1 img { margin-right: 15px; }
// .enquiry-box h6 { font-size: 15px; }
// .enquiry-box { 	padding: 20px 0px; }
// .allover-padding { padding: 8px 0px 10px 0px !important; }
// .desk-formbg { padding: 15px 15px 15px 15px; }
// .desk-from h6 { font-size: 17px; }
// .desk-from p { font-size: 13px; padding-top: 5px; line-height: 18px; }
// .contact-box { font-size: 13px; margin-top: 15px; }
// .contact-box img { margin-right: 9px; }
// .desk-from h4 { margin-top: 25px; }
// .form-contactsetting { padding: 10px 15px; }

// .edit-userpic { width: 115px; height: 115px; }
// .edit-form h1 { font-size: 18px; padding-top: 8px; }
// .edit-form p { font-size: 12px; }
// .new-upload { position: absolute; right: -7px; 	bottom: 23px; }
// .new-posttingtab ul li { margin-right: 19px; padding: 0px 14px 13px 0px; font-size: 15px; }
// .new-posttingtab ul li.active { font-size: 18px; }
// .new-seller ul li button { font-size: 12px; padding: 4px 10px; }



// }

/* Small : 320px */
@media screen and (max-width: 575px) {

.right-contentbox h6 span { width: 6px !important; height: 6px !important; }
.sparess-textbox h3 { font-size: 14px; }
.sparess-textbox h2 span { font-size: 19px; }
.sparess-textbox h2 { font-size: 18px; line-height: 27px;}
.sparess-textbox h1 { font-size: 18px; line-height: 25px;}
.sparess-textbox { padding: 15px 15px;}
.add-text h1 { padding-bottom: 0px !important; margin-bottom: 0px !important; }
.user-tabs .nav-link { font-size: 14px; padding: 5px 20px; }
.cost-tab .nav-link  {   padding: 5px 5px !important; font-size: 10px !important; }
.cost-tab .nav-link.active  { font-size: 10px !important; }
.login-form input { padding: 24px 21px; font-size: 14px; margin-top: 30px; }
.login-form p { font-size: 13px; }
.verfication input { width: 28px; padding: 10px 10px; margin-right: 10px; border: none; }
.details-rightobx .border-right { border-right: none !important; }
.my-subscriptionbox h1 { font-size: 16px; }
.deatilslogo-box { padding: 20px; height: 155px;}
.userdetails-pic { width: 135px;margin-top: -68px;}
.user-deatilsbox h1 { font-size: 23px; }
.user-deatilsbg { min-height: 110px; }
.my-subscriptionbox { padding: 10px; }
.login-btn { font-size: 14px; padding: 11px 20px; }
.user-headtext { position: relative; }
.user-headtext h1 { margin-bottom: 15px; }
.buy-table td {  padding-left: 10px; padding: 5px;}  
.buy-table thead th {  padding-left: 10px; padding: 5px;}  
.bell-img { height: 24px; }
.pro-headimg { border-radius: 50%; border: 1px solid #D49377; height: 35px; object-fit: cover; }
.tophead ul li { margin: 0 8px; }
.page-headpaddingbox { padding: 5px; }
.logo-mobile { height: 27px; }
.main-box { border-radius: 0px;  margin: 15px;}
.pageinnner-padding { padding: 5px 10px 10px 10px; }
.head-text h1 { font-size: 18px; padding-bottom: 10px; }
.seller-table thead th:first-child { font-size: 16px; }
// .seller-table td .flag-img { width: 40px; }

.seller-table td { padding: 15px 10px 0px 10px !important; }
.seller-table thead th { padding: 10px;}
.seller-table .last-text { font-size: 27px;}

.seller-table td { font-size: 12px; }
.seller-table .bold-textcontent { font-size: 18px }

.sec-table td p { font-size: 12px }
.border-top h4 { font-size: 15px; }
.padding-rightnone { padding: 5px 10px 10px 0px; }
.content-menubox {  margin-top: 5px;}
.head-content p { font-size: 17px; }
.uploadBtn { font-size: 12px; padding: 3px 15px; }
.lenth-box { padding: 6px 10px; font-size: 12px;}
.content-input { font-size: 15px; padding: 20px 16px; }
.right-contentbox h6 { font-size: 13px; padding-left: 10px; margin-top: 10px; }

.cont-imgmain .cont-manage:nth-child(2n) { margin-right: 0px;}
.bottom-imgcont { width: 128px; height: 90px; margin-bottom: 10px; }
.cont-manage { margin-right: 10px; margin-top: 15px; }

// buy=====
.secound-buybox {  height: 67px; }

//==============
.avail-Offer { padding: 22px 10px; }
.avail-Offer h1 { font-size: 20px; }
.avail-Offer p { font-size: 14px; padding-top: 5px; }
.white-btn { font-size: 12px;  padding: 5px 20px; }
.chat-table td h6 { font-size: 14px; }
.my-Adsbox h4 { font-size: 22px; }
.my-Adsbox p { font-size: 22px; }
.my-Adsbox { min-height: 290px; }
.sparessborder-btn { font-size: 12px; padding: 5px 20px; }
.sparessfill-btn { font-size: 12px; padding: 5px 20px; }
.posttable thead th { font-size: 15px; }
.edit-userallow { padding: 0px;  }
.new-upload { left: -2px; top: -2px; height: 100px; width: 100px;}
.edit-userpic {  width: 100px; height: 100px; }
.edit-form h1 { font-size: 20px; padding-top: 8px; }
.edit-form p { font-size: 14px; }
.profile-editbox { min-height: 120px; }
.edit-form { padding-left: 10px; }
.browser-post p { text-align: center; }
.forth-tablepost h1 { font-size: 13px; }
.forth-tablepost h6 { font-size: 20px; padding-top: 10px; }
.forth-tablepost h1 span {   font-size: 13px;  padding-right: 15px; }
.forth-tablepost { padding: 15px 15px; }
.posting-table thead th { line-height: 15px; }
.paid-bg { padding: 13px; }
.paid-box h1 { font-size: 27px; padding-top: 0px; }
.paid-box p {  font-size: 28px; padding-bottom: 10px; }
.paid-content h1 { font-size: 16px; }
.paid-content p { font-size: 12px;  padding-top: 8px; line-height: 20px; }
.feature-includes {  padding: 12px;  margin-top: -5px; }
.advertisement-box { padding: 0px 15px; }
.settingpadding-left {   padding-left: 10px; }
.settingpadding-left h1 { font-size: 22px; padding-bottom: 10px; }

.enquiry-box h6 { font-size: 14px; }
.enquiry-box { padding: 20px 0px; }
.settingpadding-left h1 img {  margin-right: 15px; }

.allover-padding {  padding: 10px 0px 10px 10px !important; }
.desk-formbg { padding: 15px 15px 15px 15px; }
.desk-from h6 { font-size: 25px; }
.desk-from p {  font-size: 16px;  padding-top: 10px; line-height: 20px; }
.contact-box {  font-size: 15px; margin-top: 15px; }
.desk-from h4 {  margin-top: 20px;  }
.form-contactsetting { padding: 20px 15px;  }
.new-posttingtab ul li {  display: block;  margin-right: 97px;   padding: 10px 0px 0px 0px; }
.new-posttingtab ul {  padding-bottom: 7px; }
.new-seller ul li button { font-size: 10px; padding: 4px 7px; }
.new-seller ul li { padding: 5px;}

// =====================================
.home-popcontent ul li img { height: 20px; margin-right: 10px;}
.chart-tabs .nav-item .nav-link .ch-nav-icon img { height: 30px;}
.chart-tabs .nav-item .nav-link .ch-nav-icon p{ font-size: 12px;}
.chart-tabs .nav-item .nav-link {padding: 5px 5px;}
// .chart-tabs .nav-item .nav-link {display: inline;}
// .chart-tabs {display: inline-block;}
.chart-tabs .nav-item .nav-link { margin-left: 0px; margin-bottom: 10px;}
.btn-edit { font-size: 12px; padding: 6px 22px;}
.manage-table tbody td { font-size: 12px; padding: 5px;}
.manage-table thead td { font-size: 11px;}
.btn-analy { font-size: 14px; padding: 5px;}
.ad-head-center { font-size: 22px;}
.ad-sub-head h4 { font-size: 14px;}
.ad-sub-head img { height: 20px;}
.ad-month-plans h2 span { font-size: 14px; margin-right: 3px;}
.ad-month-plans h2 { font-size: 12px; margin-top: 25px;}
.tophead{border: 0px; padding: 5px;}
.active-label { font-size: 12px !important;}
.total-revenue { padding: 0px;}
.react-stars span { font-size: 15px !important;}
.profile-feed h3 { font-size: 16px;}
.profile-feed p { font-size: 13px;}
.feed-descrip h5 { margin: 10px 0px;}
.feed-descrip h6 { font-size: 16px;}
.feed-descrip p { font-size: 15px;}
.button-field { padding-top: 15px;}
.descrip-img-selector { margin: 15px 0px !important;}
.feedback-new { padding: 5px 20px !important;}
.content-menubox ul li { font-size: 12px; margin-bottom: 6px;}
.right-contentbox { padding-left: 10px;}
.lenth-box img { height: 20px; margin-right: 8px;} 
.contact-field-area img { height: 18px; width: 18px;}
.contact-field-area p { font-size: 15px;}
.bg-newimg { height: 150px !important;}
.cloud-img { height: 25px;}
.premium-member-area p { font-size: 12px;}
.details-user ul li { line-height: 25px; font-size: 13px;}


// .ad-month-plans {margin: 10px 0px;}
// ============================================
.push-notification-pop .modal-content{ width: auto;}
.push-notification-pop {left: 0px; top: 0px;}
.push-headtext h1 { font-size: 18px;}
.push-headtext h5 { font-size: 13px}
.push-tabs-pop .nav-tabs .nav-item { margin-right: 10px;}
.push-tabs-pop .nav-tabs .nav-item .nav-link { font-size: 12px;}
// .push-notification-pop .tab-content { padding-left: 15px;}



}



@media (min-width: 320px) and (max-width: 440px) {
	 
}

 